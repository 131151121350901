import { Col, Div, DocumentStyle, Grid, Img, Row, Section } from '../../../../theme/components';
import { Heading, Text } from '../../../../theme/components/Typography';
import { LocalLink, usePageContext } from '../../../../components';
import { RichText } from 'prismic-react-tools';
import PropTypes from 'prop-types';
import React from 'react';

export const Hero = props => {
  const {
    description,
    heading,
    image: { url: image }
  } = props;

  const { strings } = usePageContext();
  const { backToProducts } = strings;

  return (
    <Section pt={{ sm: 8, xs: 6 }}>
      <Grid>
        <Row>
          <Col left xs={12}>
            <LocalLink href="/products">
              <Text underline XSmall>
                {backToProducts}
              </Text>
            </LocalLink>
          </Col>
        </Row>
        <Row mt={{ sm: 5, xs: 2.5 }}>
          <Col sm={4} xs={12}>
            <Img src={image} />
          </Col>
          <Col mt={{ sm: 0, xs: 3 }} sm={7} xs={12}>
            <Heading h2 left>
              {heading}
            </Heading>
            <Div mt={{ sm: 3.75, xs: 2 }}>
              <DocumentStyle>
                <RichText content={description} />
              </DocumentStyle>
            </Div>
          </Col>
        </Row>
      </Grid>
    </Section>
  );
};

Hero.propTypes = {
  description: PropTypes.any,
  heading: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string
  })
};
