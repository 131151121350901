import { Col, Grid, Icon, Row, Section } from '../../../../theme/components';
import { colors } from '../../../../theme';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const FileButton = styled.a.attrs({
  rel: 'noreferrer noopener',
  target: '_blank'
})`
  align-items: center;
  color: ${colors.n06};
  display: inline-flex;

  &:not(:last-of-type) {
    margin-right: 2rem;
  }

  svg {
    height: 1.5rem;
    width: auto;
  }

  svg + span {
    margin-left: 0.5rem;
  }

  &:hover {
    color: ${colors.n08};
  }
`;

const FilesWrapper = styled.div`
  border-top: 1px solid ${colors.n05};
  display: inline-block;
  padding-top: 2rem;
`;

export const Files = props => {
  const { files } = props;

  if (isEmpty(files)) {
    return null;
  }

  return (
    <Section pb={{ sm: 10, xs: 5 }}>
      <Grid>
        <Row reverse>
          <Col xs={12}>
            <FilesWrapper>
              {files.map(
                ({ file: { url }, label }, index) =>
                  url &&
                  label && (
                    <FileButton href={url} key={index}>
                      <Icon icon="fileBlankO" />
                      <span>{label}</span>
                    </FileButton>
                  )
              )}
            </FilesWrapper>
          </Col>
        </Row>
      </Grid>
    </Section>
  );
};

Files.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string
      }),
      label: PropTypes.string
    })
  )
};
