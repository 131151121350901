import { Slices } from 'prismic-react-tools';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export const Body = props => {
  const { slices } = props;

  if (isEmpty(slices)) {
    return null;
  }

  return <Slices slices={slices} />;
};

Body.propTypes = {
  slices: PropTypes.arrayOf(PropTypes.object)
};
