import { Body, Files, Hero } from './components';
import { extractFromCamelCase } from 'prismic-react-tools';
import PropTypes from 'prop-types';
import React from 'react';
import withPrismicPage from '../../HOC/withPrismicPage';

const Product = props => {
  const {
    pageContext: { document }
  } = props;

  const { body: slices, files } = document;
  const hero = extractFromCamelCase(document, 'hero');

  return (
    <>
      <Hero {...hero} />
      <Body slices={slices} />
      <Files files={files} />
    </>
  );
};

Product.propTypes = {
  pageContext: PropTypes.object
};

export default withPrismicPage(Product);
